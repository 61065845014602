import { FC, useEffect, useState, memo } from "react";
import { FieldArrayPath, useFieldArray, useForm } from "react-hook-form";

import Popup, { PopupProps } from "@/components/Popup";
import Input from "@/components/Input";
import Button from "@/components/Button";

import { DEFAULT_PHONE_INPUT_PLACEHOLDER } from "@/constants";
import { DEFAULT_INPUT_OPTIONS } from "./constants";

import { EventServerType, UserEntity } from "@/types";

import styles from "./PopupCredentials.module.scss";
import Checkbox from "../Checkbox/Checkbox";
import { InputValues } from "./types";

interface PopupCredentialsProps extends PopupProps {
  onSubmit: (data: any) => void;
  userData: UserEntity | null;
  isLoading?: boolean;
  error: Error | unknown;
}

const PopupCredentials: FC<PopupCredentialsProps> = ({
  onSubmit,
  userData,
  isLoading,
  error,
  ...props
}) => {
  const [currentDialCode, setCurrentDialCode] = useState("1");
  const [currentEvents, setCurrentEvents] = useState<EventServerType[]>([]);
  const { register, control, handleSubmit, setFocus, setValue, formState } =
    useForm<InputValues>({
      defaultValues: {
        name: "",
        phone: "",
        email: "",
        countryCode: "",
        events: [],
      },
    });

  const { fields } = useFieldArray<
    InputValues,
    FieldArrayPath<InputValues>,
    "fieldId"
  >({
    control,
    keyName: "fieldId",
    name: "events",
  });

  const { errors } = formState;

  useEffect(() => {
    setFocus("name");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setValue("countryCode", currentDialCode);
  }, [currentDialCode, setValue]);

  useEffect(() => {
    if (userData?.events) {
      setValue("events", userData.events);

      const filteredServerEvents = userData.events.filter(
        (event) => event.checked
      );

      const serverEvents = filteredServerEvents.map((event) => ({
        id: event.id,
      }));

      setCurrentEvents(serverEvents);
    }
  }, [userData, setValue]);

  const validatePhoneNumber = (value: string) => {
    return (
      !value.includes(DEFAULT_PHONE_INPUT_PLACEHOLDER) ||
      "Phone number is not valid"
    );
  };

  const onFormSubmit = ({ events, ...data }: InputValues) => {
    onSubmit({ ...data, events: currentEvents });
  };

  return (
    <Popup {...props} popupClassName={styles.popup}>
      <h3 className={styles.title}>
        Get {userData?.full_name || "User"}’s Contact card by filling your data
      </h3>
      {error && (
        <p className={styles.error}>
          {(error as Error)?.message || "Something went wrong."}
        </p>
      )}
      <form
        action="#"
        method="POST"
        className={styles.form}
        onSubmit={handleSubmit(onFormSubmit)}
      >
        <div className={styles.fields}>
          <Input
            errors={errors}
            label="Your name"
            type="text"
            placeholder="Enter your name"
            {...register("name", {
              ...DEFAULT_INPUT_OPTIONS,
              minLength: {
                value: 3,
                message: `Name should be at least 3 characters`,
              },
            })}
          />
          <Input
            errors={errors}
            label="Phone number"
            type="tel"
            placeholder="Your phone number"
            onDialCodeChange={setCurrentDialCode}
            {...register("phone", {
              ...DEFAULT_INPUT_OPTIONS,
              validate: validatePhoneNumber,
            })}
          />
          <Input
            errors={errors}
            label="Email"
            type="email"
            placeholder="Enter email"
            {...register("email", DEFAULT_INPUT_OPTIONS)}
          />
          {fields.map((field, index) => (
            <Checkbox
              key={field.fieldId}
              {...register(`events.${index}`)}
              checked={currentEvents.some((event) => event.id === field.id)}
              onChange={(e) => {
                setCurrentEvents((prevEvents) => {
                  const includesEvent = prevEvents.some(
                    (event) => event.id === field.id
                  );

                  return includesEvent
                    ? prevEvents.filter((event) => event.id !== field.id)
                    : prevEvents.concat({
                        id: field.id,
                      });
                });
              }}
            >
              {field.text}
            </Checkbox>
          ))}
        </div>
        <Button loading={isLoading} className={styles.button}>
          Connect
        </Button>
        <p className={styles.bottomText}>
          By tapping Connect, I accept
          <br />
          <a
            href="//infinitecreator.com/terms"
            target="_blank"
            rel="noreferrer"
          >
            Infinite Creator's Terms of Use
          </a>{" "}
          and{" "}
          <a
            href="//infinitecreator.com/privacy"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy.
          </a>
        </p>
      </form>
    </Popup>
  );
};

PopupCredentials.defaultProps = {
  error: null,
};

export default memo(PopupCredentials);
